var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"class-img"},[_c('img',{attrs:{"src":_vm.activeInfo.liveImg || _vm.activeInfo.coverUrl}})]),(_vm.activeInfo.discountPrice > 0 || _vm.activeInfo.price > 0)?_c('div',{staticClass:"live-pay"},[(_vm.videoPrice > 0 && _vm.activeInfo.isBrought == false)?_c('div',{staticClass:"live-price"},[_vm._m(0),_c('Price',{attrs:{"price":_vm.activeInfo.price,"discountPrice":_vm.activeInfo.discountPrice}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"live-title"},[_vm._v(" "+_vm._s(_vm.activeInfo.liveTitle || _vm.activeInfo.title || _vm.activeInfo.columnTitle)+" ")]),_c('div',{staticClass:"info-item"},[_vm._m(1),_c('span',[_vm._v("课程时间：")]),_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.activeInfo.liveBeginTime || _vm.activeInfo.createTime,"YYYY-MM-DD HH:mm"))+" ")]),_c('div',{staticClass:"info-item"},[_vm._m(2),_vm._v(" 主办方/主讲人："+_vm._s(_vm.activeInfo.liveLecturer || _vm.activeInfo.teacher || (_vm.activeInfo.teachersList ? _vm.activeInfo.teachersList.map(i => i.teacherName).join(",") : false) || "无")+" ")]),_vm._m(3),_c('div',{staticClass:"detail"},[_c('p',[_vm._v(" "+_vm._s(_vm.activeInfo.liveDescription || _vm.activeInfo.description || "  【" + (_vm.activeInfo.liveTitle || _vm.activeInfo.title || _vm.activeInfo.columnTitle) + "】由“人单合一”直播平台全程提供直播服务支持。手机端一键轻松创建直播，培训、网课、会议等场景全覆盖，直播内容云端永久存储，一键下载，更有密码、红包、答题、抽奖、签到等功能助力线上直播营销。")+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.userInfo.userRole == 'COMPANY' &&
        _vm.$route.path == '/coursemarket/mediacenter'
    ),expression:"\n      userInfo.userRole == 'COMPANY' &&\n        $route.path == '/coursemarket/mediacenter'\n    "}]},[(
        (_vm.activeInfo.discountPrice !== 0 ||
          (_vm.activeInfo.discountPrice == null && _vm.activeInfo.price !== 0)) &&
          _vm.activeInfo.isBrought == false
      )?_c('el-button',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"type":"primary"},on:{"click":_vm.buyCourse}},[_vm._v("立即购买")]):_c('el-button',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"type":"primary","disabled":_vm.isAdd},on:{"click":_vm.addCourseLib}},[_vm._v(_vm._s(_vm.isAdd ? "已加入课程库" : "+ 加入课程库")+" ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v(" 课程价格：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time"},[_c('img',{attrs:{"src":require("../../assets/images/ico/时间选择器@3x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"people"},[_c('img',{attrs:{"src":require("../../assets/images/ico/我的@3x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("../../assets/images/ico/资讯@3x.png")}})]),_c('span',[_vm._v("课程简介")])])
}]

export { render, staticRenderFns }